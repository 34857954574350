<template>
  <div>
    <MainLayout>
      <template v-slot:default>
        <!-- popup подтверждающий удаление -->
        <v-dialog v-model="downloadPopup" max-width="570">
          <v-card class="pa-8">
            <div class="object__item">
              В отчёте больше 1000 записей, поэтому он сохранится в реестр
              выгрузок. Перейдите
              <router-link :to="{ name: 'Profile' }" exact
                >на страницу профиля</router-link
              >, чтобы скачать отчёт.
            </div>

            <v-card-actions class="mt-4 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn class="btn btn_color_white" @click="downloadPopup = false">
                ОТМЕНА
              </v-btn>
              <v-btn
                :loading="dialogdownoad"
                class="btn btn_color_green"
                @click="dowloadCardsList()"
              >
                ПРОДОЛЖИТЬ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogdownoad"
          hide-overlay
          persistent
          width="600"
          height="400"
        >
          <v-card color="#95c23d" dark>
            <v-card-text>
              <div class="title title-loading">
                Ожидайте выгрузки данных
              </div>

              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <DeletePopupCommon
          v-if="deleteCkeckboxList.length > 1"
          title="карты"
          description="Вы уверены, что хотите удалить выбранный список карт?"
          :name="numCard"
          :dialog="dialog"
          @closeDeletePopup="closeDeletePopup"
          :delete-function="deleteFunction"
        />
        <DeletePopupCommon
          v-else
          title="карту"
          description="Вы уверены, что хотите удалить выбранную карту?"
          :name="numCard"
          :dialog="dialog"
          @closeDeletePopup="closeDeletePopup"
          :delete-function="deleteFunction"
        />
        <!-- /popup подтверждающий удаление-->
        <TableFilters :queries="queries">
          <template v-slot:filter-title>
            <span v-if="sourceObject.count">
              <span class="title-filter">Всего:</span>{{ sourceObject.count }}
              <!--            <span>карт</span>-->
            </span>
          </template>
          <template v-slot:download-button>
            <v-btn
              v-if="currentRole != 31"
              @click="downloadList"
              class="btn btn_color_grey btn-unload flex-right mr-4"
              :disabled="loadingBtn || sourceObject.results.length == 0"
              :loading="loadingBtn"
            >
              Выгрузить
            </v-btn>
          </template>
          <template v-slot:add-button>
            <router-link
              v-if="currentRole != 31"
              to="createCardPage"
              class="btn btn-add btn_color_green"
            >
              Добавить карту
            </router-link>
          </template>

          <template
            v-if="deleteCkeckboxList.length > 1 && currentRole != 31"
            v-slot:delete-button
          >
            <v-btn
              :disabled="isDisabled"
              color="#EB5C6D"
              class="btn btn-delete btn_color_red"
              @click="clickDelete"
              style="color:white"
            >
              Удалить карты
            </v-btn>
          </template>
          <template
            v-else-if="deleteCkeckboxList.length <= 1 && currentRole != 31"
            v-slot:delete-button
          >
            <v-btn
              :disabled="isDisabled"
              color="#EB5C6D"
              class="btn btn-delete btn_color_red"
              @click="clickDelete"
              style="color:white"
            >
              Удалить карту
            </v-btn>
          </template>
        </TableFilters>
        <TableLayout
          :loading="isDateLoaded"
          to="card"
          :table-data="sourceObject.results"
          :header="header"
          :source-object="sourceObject"
          delete-all
          dropDown
          v-if="currentRole != 31"
        >
          <template slot-scope="{ itemFather }">
            <v-checkbox
              class="ma-0 pa-0 checkbox-type"
              :value="itemFather.id"
              v-model="deleteCkeckboxList"
              hide-details
            ></v-checkbox>
          </template>
        </TableLayout>
        <TableLayout
          :loading="isDateLoaded"
          disable-router
          :table-data="sourceObject.results"
          :header="header"
          :source-object="sourceObject"
          dropDown
          v-else
        >
        </TableLayout>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";
import tableColsCards from "@/helpers/tableCols/cards";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CardsPage",
  data() {
    return {
      loadingBtn: false,
      dialog: false, // диалоговое окно popup удаления
      dialogdownoad: false,
      downloadPopup: false,
      numCard: "", // номер карты
      idCard: "", // id карты
      queries: query.cards
    };
  },
  components: { TableFilters, TableLayout, MainLayout, DeletePopupCommon },
  computed: {
    currentRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    },
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },

    header() {
      if (this.currentRole != 31) {
        return tableColsCards
          .filter(el => el.text !== "Пользователь" && el.text !== "Комментарий")
          .filter(el =>
            el.userRole.includes(this.$store.getters.getCurrentRole)
          );
      } else {
        return tableColsCards.filter(el =>
          el.userRole.includes(this.$store.getters.getCurrentRole)
        );
      }
    },
    headerDownload() {
      return tableColsCards.filter(
        el =>
          el.text !== "Пользователь" &&
          el.text !== "ФИО" &&
          el.text !== "Комментарий"
      );
    },
    sourceObject() {
      if (this.currentRole != 31) {
        return this.$store.getters.getPageCardsList;
      } else return this.$store.getters.getOrganizationCardsList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    }
  },
  methods: {
    deleteFunction() {
      let data = {
        cards_to_delete: this.deleteCkeckboxList
      };
      this.$store
        .dispatch("deleteArrCards", { data })
        .then(() => {
          this.isDateLoaded = true;
          if (this.currentRole != 31) {
            this.$store.dispatch("fetchPageCardsList").finally(() => {
              this.isDateLoaded = false;
            });
          } else {
            this.$store.dispatch("fetchKindergartensCardsList").finally(() => {
              this.isDateLoaded = false;
            });
          }
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.cards)
          );
        });
    },

    clickDelete() {
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    downloadList() {
      if (this.sourceObject.count > 1000) {
        this.downloadPopup = true;
      } else {
        this.loadingBtn = true;
        this.$store
          .dispatch("downloadPageCardsList", this.headerDownload)
          .then(() => {
            this.loadingBtn = false;
          });
      }
    },
    dowloadCardsList() {
      this.dialogdownoad = true;
      this.downloadPopup = false;

      this.$store.dispatch("downloadReportsAllCardsList").then(() => {
        setTimeout(() => (this.dialogdownoad = false), 5000);
      });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.dispatch("clearCheckedList");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    if (this.currentRole != 31) {
      this.$store.dispatch("fetchPageCardsList").finally(() => {
        this.isDateLoaded = false;
      });
    } else {
      this.$store.dispatch("fetchKindergartensCardsList").finally(() => {
        this.isDateLoaded = false;
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("CLEAR_CHECKED_ALL");
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });
      this.isDateLoaded = true;
      if (this.currentRole != 31) {
        this.$store.dispatch("fetchPageCardsList").finally(() => {
          this.isDateLoaded = false;
        });
      } else {
        this.$store.dispatch("fetchKindergartensCardsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store
      .dispatch("clearCardsPage")
      .then(() => {
        this.$store.dispatch("clearCheckedList");
      })
      .then(() => next());
  }
};
</script>
